<template>
<div class="w-100 text-left">
    <b-modal
        v-model="modal"
        size="xl"
        title="Esconder evento de empresas selecionadas ou inserir na lista exclusiva de eventos permitidos para cada base"
        hide-footer
    >
        <b-form-input
            v-model="search"
            type="search"
            placeholder="Buscar e adicionar empresas... "
            debounce="600"
            class="mb-4"
        />
        <b-row style="padding: 1rem;display: flex;">
            <b-input-group size="sm">
                <b-form-checkbox id="StaDebBordero"
                                v-model="markAll"
                                @change="markAllItems"
                                value="1">
                <span>Esconder das bases os itens exibidos</span>
                </b-form-checkbox>
                <b-form-checkbox id="StaDebBordero2"
                                v-model="markAllCorporate"
                                @change="markAllItemsCorporate"
                                value="2">
                <span>Inserir na lista exclusiva os itens exibidos</span>
                </b-form-checkbox>
            </b-input-group>
        </b-row>
        <b-table
            striped="striped"
            outlined="outlined"
            class="fontSize tableClicked bg-white"
            small="small"
            hover="hover"
            responsive
            show-empty
            empty-text="Sem items"
            style="height:500px"
            :total-rows="allItems.length"
            :fields="fields"
            :items="allItems"
            :busy="loading"
        >

        <template slot="actions" slot-scope="data">
            <b-button
                size="sm"
                title="Esconder este evento da base, listando apenas os outros eventos disponíveis"
                variant="outline-success"
                v-if="selectedItems && !selectedItems.some(item => item.id_corporativo === (data.item.id || data.item.id_corporativo))"
                @click="addItem(data.item.id)"
            >
                Esconder da base
            </b-button>
            <b-button
                v-else
                size="sm"
                title="Remover parceiro"
                variant="danger"
                @click="removeItem(data.item.id)"
            >
                Exibir na base
            </b-button>
            <b-button
                size="sm"
                title="Exibir somente este evento e outros selecionados para essa base"
                variant="outline-primary"
                v-if="!data.item.isActive"
                @click="changeEventToCorporateExclusive('add', data.item)"
            >
                Adicionar na lista
            </b-button>
            <b-button
                v-else
                size="sm"
                title="Remover parceiro"
                variant="danger"
                @click="changeEventToCorporateExclusive('delete', data.item)"
            >
               Remover da lista
            </b-button>
        </template>
        
        </b-table>
    </b-modal>

</div>
</template>

<script>
import { func } from "@/functions";
import { corporateService } from '@/components/common/services/corporate';
import { eventService } from "@/components/common/services/event";

export default {
    props: {
        eventId: {
            type: [Number, String],
            required: true
        }
    },
    mixins: [func],
    data(){
        return {
            modal: true,
            markAll: false,
            markAllCorporate: false,
            search: '',
            items: [],
            selectedItems: [],
            loading: false,
            fields: {
                name: {
                    label: 'Nome'
                },
                url: {
                    label: 'Domínio'
                },
                actions: {
                    label: ''
                }
            },
        }
    },
    watch: {
        eventId: {
            handler: 'setSelectedItems',
            immediate: true
        },
        modal(event) {
            this.$emit('close', event)
        }
    },
    computed: {
        allItems(){
            if (this.search === '') {
                return this.items;
            }
            return this.items.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()));
        }
    },
    async mounted () {
        await this.setItems()
    },
    methods: {
        async markAllItems () {
            const ids = this.allItems.map(item => item.id || item.id_corporativo) 
            await this.save(ids)
            this.search = '';
            this.markAll = false;
        },
        async markAllItemsCorporate() {
            const ids = this.allItems.map(item => item.id || item.id_corporativo) 
            await this.saveExclusive(ids)
            this.search = '';
            await this.setItems()
            this.markAllCorporate = false;
        },
        async changeEventToCorporateExclusive (type, item) {
            this.loading = true;
            if (type === 'add') {
                await corporateService.changeCorporateExclusive('', this.getLoggedId(), item.id, this.eventId)
            }
            if (type === 'delete') {
                await corporateService.changeCorporateExclusive('delete', this.getLoggedId(), item.id, this.eventId)
            }
            await this.setItems()
            this.loading = false;
        },
        async setItems(){
            this.loading = true;
            const items = await corporateService.list(this.search, 1, 2000, this.eventId)
            this.items = items;
            this.loading = false;
        },
        async setSelectedItems(){
            const selectedItems = await eventService.getExcludedCompanies(this.getLoggedId(), this.eventId)

            this.selectedItems = selectedItems;
        },
        async addItem(id){
            const ids = this.selectedItems.map(item => item.id || item.id_corporativo) 

            ids.push(id);
            
            await this.save(ids)

        },
        async removeItem(id){
            const ids = this.selectedItems.map(item => item.id || item.id_corporativo) 

            ids.splice(ids.indexOf(id), 1);

            await this.save(ids)
        },
        async saveExclusive (ids) {
            return corporateService
                .saveExclusiveCompanies(this.getLoggedId(), this.eventId, ids)
                .then(({ success }) => {
                    if (success){
                        return this.toastSuccess("Salvo com sucesso")
                    }
                    
                    this.toastError("Erro ao salvar")
                })
                .catch(() => this.toastError("Erro ao salvar"))
                .finally(() => this.setSelectedItems())
        },
        async save(ids){

            return eventService
                .saveExcludedCompanies(this.getLoggedId(), this.eventId, ids)
                .then(({ success }) => {
                    if (success){
                        return this.toastSuccess("Salvo com sucesso")
                    }
                    
                    this.toastError("Erro ao salvar")
                })
                .catch(() => this.toastError("Erro ao salvar"))
                .finally(() => this.setSelectedItems())
        }
    }
}
</script>